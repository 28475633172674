import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosHelper from "../helpers/AxiosHelper";
import logo from "../assets/LOGO_TECH_MEDIO.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as StarFilled } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faStar as EmptyStar } from "@fortawesome/free-regular-svg-icons";
import styles from "./StarsCounter.module.scss";

import logoGoogle from "../assets/google.svg.png";
import logoTrip from "../assets/Tripadvisor_lockup_horizontal_secondary_registered.svg";
import logoBooking from "../assets/Booking-Logo-PNG-e1507819203318.png";
import logoTrust from "../assets/Trustpilot_Logo_(2022).svg.png";

const StarsCounter: React.FC = () => {
  const params = useParams();
  const [stars, setStars] = useState(0);
  const [text, setText] = useState("");
  const [showBtn, setShowBtn] = useState(true);
  // const [ciccio, setCiccio] = useState("ciao");

  const changePath = async () => {
    console.log(params.id);
    if (params.id)
      await axiosHelper
        .getClientUrl(params.id)
        .then((response) => {
          // console.log(response);
          console.log(response.link);
          // setCiccio(response.link);
          // });
          window.location.replace(response.link);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const changeStars = async (n: number) => {
    if (stars === 0) {
      setStars(n);
      if (n > 3 && params.id) {
        await axiosHelper
          .getClientUrl(params.id)
          .then((response) => {
            // console.log(response);
            console.log(response.link);
            // setCiccio(response.link);
            // });
            window.location.replace(response.link);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const sendRev = async () => {
    console.log(params.id);
    if (params.id) {
      await axiosHelper
        .createRev(parseInt(params.id), text)
        .then((response) => {
          console.log(response);
          setShowBtn(false);
        });
    }
  };

//   useEffect(() => {
//     changePath();
//   }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: `${params.theme === "dark" ? "black" : "white"}`,
          height: "100vh",
          padding: "5rem",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div
            className={` col-12  text-center mt-5 light-title fw-bold ${
              params.theme === "dark" ? "color-white" : "color-black"
            }`}
          >
            How would you rate us?
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center  col-12   mt-5  ">
            <div className="p-2" onClick={() => changeStars(1)}>
              <FontAwesomeIcon
                icon={stars >= 1 ? StarFilled : (EmptyStar as IconProp)}
                style={{
                  fontSize: "3rem",
                  color: `${
                    stars >= 1
                      ? "#ffc000"
                      : params.theme === "dark"
                      ? "white"
                      : "black"
                  }`,
                }}
              />
            </div>
            <div className="p-2" onClick={() => changeStars(2)}>
              <FontAwesomeIcon
                icon={stars >= 2 ? StarFilled : (EmptyStar as IconProp)}
                style={{
                  fontSize: "3rem",
                  color: `${
                    stars >= 2
                      ? "#ffc000"
                      : params.theme === "dark"
                      ? "white"
                      : "black"
                  }`,
                }}
              />
            </div>
            <div className="p-2" onClick={() => changeStars(3)}>
              <FontAwesomeIcon
                icon={stars >= 3 ? StarFilled : (EmptyStar as IconProp)}
                style={{
                  fontSize: "3rem",
                  color: `${
                    stars >= 3
                      ? "#ffc000"
                      : params.theme === "dark"
                      ? "white"
                      : "black"
                  }`,
                }}
              />
            </div>
            <div className="p-2 " onClick={() => changeStars(4)}>
              <FontAwesomeIcon
                icon={stars >= 4 ? StarFilled : (EmptyStar as IconProp)}
                style={{
                  fontSize: "3rem",
                  color: `${
                    stars >= 4
                      ? "#ffc000"
                      : params.theme === "dark"
                      ? "white"
                      : "black"
                  }`,
                }}
              />
            </div>
            <div className="p-2" onClick={() => changeStars(5)}>
              <FontAwesomeIcon
                icon={stars >= 5 ? StarFilled : (EmptyStar as IconProp)}
                style={{
                  fontSize: "3rem",
                  color: `${
                    stars >= 5
                      ? "#ffc000"
                      : params.theme === "dark"
                      ? "white"
                      : "black"
                  }`,
                }}
              />
            </div>
          </div>
        </div>
        {stars < 4 && stars > 0 && (
          <>
            <div
              className={`mt-10 d-flex flex-row justify-content-center col-12 sub-title fw-bold ${
                params.theme === "dark" ? "color-white" : "color-black"
              } `}
            >
              Dicci come possiamo migliorare
            </div>
            <div
              className={`mt-5 d-flex flex-row   justify-content-center  col-12 ${styles.textareaContainer}`}
            >
              <textarea
                value={text}
                placeholder="Scrivi qui il tuo commento"
                onChange={(e: any) => setText(e.target.value)}
                className={`col-12 ${
                  params.theme === "dark" ? `${styles.white}` : `${styles.dark}`
                }`}
              />
            </div>
            <div className="mt-5 d-flex flex-row justify-content-center col-12 sub-title fw-bold ">
              {showBtn && (
                <div
                  onClick={() => sendRev()}
                  className={` ${
                    params.theme === "dark" ? "btn-light" : "btn-dark"
                  } rounded`}
                >
                  Send us
                </div>
              )}
            </div>
          </>
        )}
        {/* {stars >= 4 && (
          <>
            <div className=" d-flex flex-column align-items-center justify-content-center col-12 mt-7 ">
              <div
                className={`d-flex flex-row align-items-center justify-content-center col-12  small-title-rev text-center ${
                  params.theme === "dark" ? "color-white" : "color-black"
                } `}
              >
                Lasciaci
              </div>
              <div
                className={`d-flex flex-row mt-3 align-items-center justify-content-center col-12  small-title-rev text-center ${
                  params.theme === "dark" ? "color-white" : "color-black"
                }`}
              >
                una recensione su
              </div>
            </div>
            <div className=" d-flex flex-row align-items-center justify-content-center col-12 mt-10 ">
              <div
                className={`d-flex flex-row align-items-center justify-content-center  col-9 ${
                  params.theme === "dark" ? styles.buttonDark : styles.button
                }`}
              >
                <div className={`${styles.internal} `}>
                  <img src={logoGoogle} />
                </div>
              </div>
            </div>
            <div className=" d-flex flex-row align-items-center justify-content-center col-12 mt-5 ">
              <div
                className={`d-flex flex-row align-items-center justify-content-center  col-9 ${
                  params.theme === "dark" ? styles.buttonDark : styles.button
                }`}
              >
                <div className={`${styles.internal} `}>
                  <img src={logoTrip} />
                </div>
              </div>
            </div>
            <div className=" d-flex flex-row align-items-center justify-content-center col-12 mt-5 ">
              <div
                className={`d-flex flex-row align-items-center justify-content-center  col-9 ${
                  params.theme === "dark" ? styles.buttonDark : styles.button
                }`}
              >
                <div className={`${styles.internal} `}>
                  <img src={logoBooking} />
                </div>
              </div>
            </div>
            <div className=" d-flex flex-row align-items-center justify-content-center col-12 mt-5 ">
              <div
                className={`d-flex flex-row align-items-center justify-content-center  col-9 ${
                  params.theme === "dark" ? styles.buttonDark : styles.button
                }`}
              >
                <div className={`${styles.internal} `}>
                  <img src={logoTrust} />
                </div>
              </div>
            </div>
          </>
        )} */}
      </div>
    </>
  );
};

export default StarsCounter;
