import { Route, Routes } from "react-router-dom";
import { ROUTEPATHS } from "./routes/routePaths";
import ClientRedirector from "./routes/ClientRedirector";
import MultiRev from "./routes/MultiRev";
import StarsCounter from "./routes/StarsCounter";

const Container = () => {
  return (
    <Routes>
      <Route path={`${ROUTEPATHS.CLIENT}/:id`} element={<ClientRedirector />} />
      <Route path={`${ROUTEPATHS.MULTIREV}/:id/:theme`} element={<MultiRev />} />
      <Route path={`${ROUTEPATHS.STARS}/:id/:theme`} element={<StarsCounter />} />
    </Routes>
  );
};
export default Container;
