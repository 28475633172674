import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosHelper from "../helpers/AxiosHelper";
import logo from "../assets/LOGO_TECH_MEDIO.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoGoogle from "../assets/google.svg.png";
import logoTrip from "../assets/Tripadvisor_lockup_horizontal_secondary_registered.svg";
import logoBooking from "../assets/Booking-Logo-PNG-e1507819203318.png";
import logoTrust from "../assets/Trustpilot_Logo_(2022).svg.png";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import styles from "./MultiRev.module.scss";

const MultiRev: React.FC = () => {
  const params = useParams();
  // const [ciccio, setCiccio] = useState("ciao");

  useEffect(() => {
    console.log(params.theme);
    console.log(params.id);
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: `${params.theme === 'dark' ? 'black' : 'white'}`,
          height: "100vh",
          width: "100%",
        }}
      >
        <div className="d-flex flex-row align-items-center justify-content-center sub-title  col-12 ">
          <div className="d-flex flex-row align-items-center justify-content-around sub-title col-6 px-3 mt-10 ">
            <FontAwesomeIcon
              icon={faStar as IconProp}
              //   style={{ color: "FFD700" }}
              style={{ color: "FFC000", fontSize: "2.5rem" }}
            />
            <FontAwesomeIcon
              icon={faStar as IconProp}
              style={{ color: "FFC000", fontSize: "2.5rem" }}
            />
            <FontAwesomeIcon
              icon={faStar as IconProp}
              //   style={{ color: "FFEA00" }}
              style={{ color: "FFC000", fontSize: "2.5rem" }}
            />
            <FontAwesomeIcon
              icon={faStar as IconProp}
              //   style={{ color: "FFBF00" }}
              style={{ color: "FFC000", fontSize: "2.5rem" }}
            />
            <FontAwesomeIcon
              icon={faStar as IconProp}
              //   style={{ color: "FFD700" }}
              style={{ color: "FFC000", fontSize: "2.5rem" }}
            />
          </div>
        </div>
        <div className=" d-flex flex-column align-items-center justify-content-center col-12 mt-7 ">
          <div
            className={`d-flex flex-row align-items-center justify-content-center col-12  small-title-rev text-center ${
              params.theme === "dark" ? "color-white" : "color-black"
            } `}
          >
            {/* Lascia la tua recensione su */}
            Lasciaci
          </div>
          <div
            className={`d-flex flex-row mt-3 align-items-center justify-content-center col-12  small-title-rev text-center ${
              params.theme === "dark" ? "color-white" : "color-black"
            }`}
          >
            {/* Lascia la tua recensione su */}
            una recensione su
          </div>
        </div>
        <div className=" d-flex flex-row align-items-center justify-content-center col-12 mt-10 ">
          <div
            className={`d-flex flex-row align-items-center justify-content-center  col-9 ${
              params.theme === "dark" ? styles.buttonDark : styles.button
            }`}
          >
            <div className={`${styles.internal} `}>
              <img src={logoGoogle} />
            </div>
          </div>
        </div>
        <div className=" d-flex flex-row align-items-center justify-content-center col-12 mt-5 ">
          <div
            className={`d-flex flex-row align-items-center justify-content-center  col-9 ${
              params.theme === "dark" ? styles.buttonDark : styles.button
            }`}
          >
            <div className={`${styles.internal} `}>
              <img src={logoTrip} />
            </div>
          </div>
        </div>
        <div className=" d-flex flex-row align-items-center justify-content-center col-12 mt-5 ">
          <div
            className={`d-flex flex-row align-items-center justify-content-center  col-9 ${
              params.theme === "dark" ? styles.buttonDark : styles.button
            }`}
          >
            <div className={`${styles.internal} `}>
              <img src={logoBooking} />
            </div>
          </div>
        </div>
        <div className=" d-flex flex-row align-items-center justify-content-center col-12 mt-5 ">
          <div
            className={`d-flex flex-row align-items-center justify-content-center  col-9 ${
              params.theme === "dark" ? styles.buttonDark : styles.button
            }`}
          >
            <div className={`${styles.internal} `}>
              <img src={logoTrust} />
            </div>
          </div>
        </div>

        {/* <img src={logo} style={{ width: "75%" }} /> */}
      </div>
    </>
  );
};

export default MultiRev;
