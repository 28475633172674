import axios from "axios";

class axiosHelper {
  constructor() {
    console.log("constructor di axioshelper");
  }

  getClientUrl(idClient: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/client/revGoogle/${idClient}`)
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  createRev(idClient: number, text: string) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/clientReview/${idClient}`, {
        text: text,
      })
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
export default new axiosHelper();
